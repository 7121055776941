/*Chart Colours*/
$chart-red: #ef2d6b;
$chart-red-orange: #ff5e30;
$chart-orange: #ffa930;
$chart-yellow: #ffd430;
$chart-lime: #b3f52e;
$chart-lime-green: #89f02d;
$chart-green: #28d578;
$chart-blue: #3282CC;
$chart-blue-solid: #523ed3;
$chart-purple: #a12ece;

/*Chart Mono Colours*/
$chart-blue-dark: #085192;
$chart-blue-mid-dark: #0e6dc1;
$chart-blue-mid-light: #5499d7;
$chart-blue-light: #7fb5e5;

.page-dashboard-content {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	column-gap: 1rem;
	row-gap: 1rem;
	justify-items: stretch;
	align-items: stretch;
	justify-content: space-between;
	align-content: start;
	grid-auto-flow: row;
	min-height:calc(100vh - 58px - 66px - 3rem - 34px);

	@include media-breakpoint-up(sm) {
		grid-template-columns: 1fr 1fr;
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		align-content: space-between;
		min-height:calc(100vh - 58px - 66px - 3rem - 34px);
	}


	.dashboard-item {
		// .cell-#col-#row
		// lg+ #col 1-2 xl+ #col 1-12
		// xl+ #row 1-4
		// e.g .cell-4-2 has width:33%; height:50%
		&.cell-1-1 {
			@include media-breakpoint-up(lg) {
			}

			@include media-breakpoint-up(xl) {
			}
		}

		&.cell-2-1 {

			@include media-breakpoint-up(sm) {
				grid-column: auto;
				grid-row: auto / span 1;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 4;
				grid-row: auto / span 1;
			}

			@include media-breakpoint-up(xl) {
				grid-column: auto / span 2;
				grid-row: auto / span 1;
			}
		}

		&.cell-3-1 {

			@include media-breakpoint-up(sm) {
				grid-column: auto;
				grid-row: auto / span 1;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 3;
				grid-row: auto / span 1;
			}
		}

		&.cell-3-3 {

			@include media-breakpoint-up(sm) {
				grid-column: auto;
				grid-row: auto / span 3;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 3;
				grid-row: auto / span 3;
			}
		}

		&.cell-6-2 {

			@include media-breakpoint-up(sm) {
				grid-column: auto / span 3;
				grid-row: auto / span 2;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 6;
				grid-row: auto / span 2;
			}
		}

		&.cell-6-3 {

			@include media-breakpoint-up(sm) {
				grid-column: auto / span 3;
				grid-row: auto / span 3;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 6;
				grid-row: auto / span 3;
			}
		}

		&.cell-6-4 {
			grid-column: auto;
			grid-row: auto;

			@include media-breakpoint-up(sm) {
				grid-column: auto / span 2;
				grid-row: auto;
			}

			@include media-breakpoint-up(md) {
				grid-column: auto / span 2;
				grid-row: auto;
			}

			@include media-breakpoint-up(lg) {
				grid-column: auto / span 6;
				grid-row: auto / span 4;
			}

			@include media-breakpoint-up(xl) {
				grid-column: auto / span 6;
				grid-row: auto / span 4;
			}
		}
	}
}

.dashboard-item {
	@include flex-container(row, wrap, space-between, stretch, flex-start);
	@include whitebox();
	padding: 0;
	border: 1px solid $white;
	min-height: 120px;
	>*{
		height:100%;
		width:100%;
	}
}

.dashboard-widget{
	border:none;
	height:100%;
	.card-header{
	  font-weight: $font-weight-bold;
	  text-transform: uppercase;
	  padding:0.5rem 1rem;
	  background: $white;
	  border-bottom:none;
	}
	.card-body{
		.chart,
		.apexcharts-canvas{
			height:100%;
		}
	  padding:1rem;
	  .card-subtitle{
		font-weight: $font-weight-bold;
	  }
	  .display-5 {
		font-weight: $font-weight-bold;
		line-height: $line-height-sm;
		font-size: 1.5rem;
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 2.5rem;
		}
	  }
	}
  }
@mixin animation ($name, $duration: 300ms, $delay: 0, $ease: ease) {
	-webkit-animation: $name $duration $delay $ease;
	-moz-animation: $name $duration $delay $ease;
	-ms-animation: $name $duration $delay $ease;
}

@mixin transition ($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
}

@mixin transform($string) {
	-webkit-transform: $string;
	-moz-transform: $string;
	-ms-transform: $string;
	-o-transform: $string;
}

@mixin scale ($factor) {
	-webkit-transform: scale($factor);
	-moz-transform: scale($factor);
	-ms-transform: scale($factor);
	-o-transform: scale($factor);
}

@mixin rotate ($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	-o-transform: rotate($deg);
}

@mixin skew ($deg, $deg2) {
	-webkit-transform: skew($deg, $deg2);
	-moz-transform: skew($deg, $deg2);
	-ms-transform: skew($deg, $deg2);
	-o-transform: skew($deg, $deg2);
}

@mixin translate ($x, $y: 0) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
}

@mixin translate3d ($x, $y: 0, $z: 0) {
	-webkit-transform: translate3d($x, $y, $z);
	-moz-transform: translate3d($x, $y, $z);
	-ms-transform: translate3d($x, $y, $z);
	-o-transform: translate3d($x, $y, $z);
}

@mixin perspective ($value: 1000) {
	-webkit-perspective: $value;
	-moz-perspective: $value;
	-ms-perspective: $value;
	perspective: $value;
}

@mixin transform-origin ($x: center, $y: center) {
	-webkit-transform-origin: $x $y;
	-moz-transform-origin: $x $y;
	-ms-transform-origin: $x $y;
	-o-transform-origin: $x $y;
}



@mixin flex-container($flexdirection: row, $flexwrap: wrap, $justifycontent: flex-start, $alignitems: flex-start, $aligncontent: flex-start) {
	display: flex;
	flex-direction: $flexdirection;
	flex-wrap: $flexwrap;
	justify-content: $justifycontent;
	align-items: $alignitems;
	align-content: $aligncontent;
}

@mixin flex-element($order: 0, $flexgrow: 1, $flexshrink: 1, $flexbasis: auto) {
	order: $order;
	flex: $flexgrow $flexshrink $flexbasis;
}
@mixin flex-element-fixed($order: 0, $flexgrow: 1, $flexshrink: 1, $flexbasis: auto) {
	order: $order;
	flex: $flexgrow $flexshrink $flexbasis;
	max-width:$flexbasis;
	width: $flexbasis;
	min-width: $flexbasis;
}

@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}

	&::-moz-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}

	&:-moz-placeholder {
		color: $color;
	}
}

@mixin object-fit($fit: fill, $position: null) {
	-o-object-fit: $fit;
	object-fit: $fit;

	@if $position {
		-o-object-position: $position;
		object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	}
	@else {
		font-family: 'object-fit: #{$fit}';
	}
}

@mixin icon-font($size: 24px) {
	/*font-family: $font-family-icon;
	font-style: normal;
	font-weight: 400;
	speak: none;*/
	content:'';
	display: inline-block;
	width: $size;
	height: $size;
	background-color: transparent;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

@mixin btn {
	padding: 0.5rem 1rem;
	font-weight: 500;
	border-radius: 4px;
	line-height: 1rem;
	font-family: $font-family-base;
	outline: none !important;

	@include media-breakpoint-up(lg) {
		padding: 0.5rem 2rem;
	}

	&:active,
	&:focus {
		outline: none;
	}

	&.icon-btn {
		&:before {
			font-family: $font-family-icon;
			display: none;
			vertical-align: middle;
			margin-right: 1rem;
			font-size: 16px;
			font-weight: $font-weight-normal;

			@include media-breakpoint-up(xl) {
				display: inline-block;
			}
		}

		&.icon-only {
			&:before {
				display: inline-block;
				margin-right: 0;
			}
		}
	}

	&.bluebtn,
	&.btn-primary {
		border: 1px solid $st-dark-blue;
		color: $white;
		@include gradient-y($st-blue, $st-dark-blue);

		&:hover {
			@include gradient-y($st-dark-blue, $st-dark-blue);
			color: $white;
			box-shadow: 0 0 15px $shadow-20;
		}

		&:active,
		&:focus {
			@include gradient-y($st-dark-blue, $st-blue);
			border: 1px solid $st-blue;
			box-shadow: 0 0 15px $shadow-20;
		}
	}

	&.btn-dark{
		border: 1px solid $gray-900;
		color: $white;
		@include gradient-y($gray-800, $gray-900);

		&:hover {
			@include gradient-y($gray-900, $gray-900);
			color: $white;
			box-shadow: 0 0 15px $shadow-20;
		}

		&:active,
		&:focus {
			@include gradient-y($gray-900, $gray-800);
			border: 1px solid $gray-700;
			box-shadow: 0 0 15px $shadow-20;
		}
	}
	&.btn-light{
		border: 1px solid $gray-200;
		color: $body-color;
		@include gradient-y($gray-100, $gray-150);

		&:hover {
			@include gradient-y($gray-150, $gray-150);
			color: $st-blue;
			border: 1px solid $gray-300;
		}

		&:active,
		&:focus {
			@include gradient-y($gray-150, $gray-100);
			border: 1px solid $gray-300;
		}
	}

	&.whitebtn,
	&.secondary-btn,
	&.btn-secondary {
		border: 1px solid $gray-400;
		color: $gray-900;
		@include gradient-y($white, $gray-100);

		&:hover {
			@include gradient-y($gray-100, $gray-100);
			color: $gray-1000;
		}

		&:active,
		&:focus {
			@include gradient-y($gray-100, $white);
			border: 1px solid $gray-400;
			color: $gray-1000;
		}
	}

	&.btn-outline-primary {
		color: $st-blue;
		border-color: $st-blue;

		&:hover {
			@include gradient-y($st-dark-blue, $st-dark-blue);
			color: $white;
			box-shadow: 0 0 15px $shadow-20;
		}

		&:active,
		&:focus {
			@include gradient-y($st-dark-blue, $st-blue);
			border: 1px solid $st-blue;
			box-shadow: 0 0 15px $shadow-20;
		}
	}

	&.btn-outline-light {
		color: $gray-500;
	}

	&.btn-link {
		padding: 0;
		outline: none;
	}

	&.redbtn, &.reject-btn {
		border: 1px solid $red;
		color: $white;
		@include gradient-y(lighten($red, 10%), $red);

		&:hover {
			@include gradient-y($red, $red);
			color: $white;
		}

		&:active,
		&:focus {
			@include gradient-y($red, lighten($red, 10%));
			border: 1px solid $red;
		}
	}

	&.greenbtn,
	&.accept-btn {
		border: 1px solid $green;
		color: $white;
		@include gradient-y(lighten($green, 10%), $green);

		&:hover {
			@include gradient-y($green, $green);
			color: $white;
		}

		&:active,
		&:focus {
			@include gradient-y($green, lighten($green, 10%));
			border: 1px solid $green;
		}
	}

	&.bigbtn {
		padding: 0.75rem 2rem;
		font-size: $font-size-lg;
	}

	&.smallbtn {
		padding: 0.3rem 0.75rem;
		font-size: $font-size-xs;
		font-weight: 400;
	}

	&.xbigbtn {
		padding: 1rem 2.5rem;
		font-size: $h4-font-size;
	}

	&.no-btn {
		background: none !important;
		color: $st-blue !important;
		border: none !important;
		box-shadow: none !important;
		text-decoration: underline;
	}
}

@mixin whitebox($padding: 1rem) {
	padding: $padding;
	background: $white;
	//border: 1px solid $gray-200;
	//box-shadow: 0 6px 12px $shadow-7;
}

html, body {
	-webkit-font-smoothing: antialiased !important;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	min-height:100vh;
}
input[type="datetime-local"],
input[type="time"],
input[type="date"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
}
.form-col-label{
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	line-height:1;
}
input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="datetime-local"],
input[type="time"],
input[type="date"],
textarea,
select,
.form-select,
.form-control {
	width: 100%;
	border: 1px solid $gray-500 !important;
	border-radius: 0 !important;
	padding: 0.2rem 0.35rem;
	height: 34px;
	color: $body-color;
	font-size: $font-size-base;
	&:focus {
		outline-color: $st-blue !important;
	}
	&:disabled,
	&[disabled="disabled"] {
		background: $gray-250;
		color: $gray-600;
	}
	&.form-control-plaintext,
	&[readonly]{
		border-color: transparent !important;
		color: $body-color;
		&:focus,
		&:focus-visible {
			outline-color: transparent !important;
			outline-style: none !important;
			outline-width: 0 !important;
			box-shadow: none !important;
		}
	}
	&.form-control-plaintext{
		padding: 0.2rem 0;
	}
}
input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="datetime-local"],
input[type="time"],
input[type="date"]{
	line-height:34px;
}
select {
	option {
		&:disabled {
			background: $gray-250;
			color: $gray-600;
		}
	}

	&:disabled {
		background: $gray-250;
		color: $gray-600;
	}
}
.form-select,
select{
	padding-right:2rem !important;
}
input[type="checkbox"],
input[type="radio"] {
	height: 1rem;
	width: 1rem;
	vertical-align:text-bottom;
}
.form-col-item-empty{
	min-height:34px;
}
a, .btn, button, .animated {
	@include transition(all 300ms ease);
}

.fastanimated {
	@include transition(all 150ms ease);
}

button,
.btn {
	@include btn;
}
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
	-webkit-appearance: none; // 2
}
.btn-group{
	.btn,
	button{
		margin:0 !important;
		&:not(:first-child){
			border-left-width: 0 !important;
		}
		&.dropdown-toggle{
			padding-left:1rem;
			padding-right:1rem;
		}
	}
}
.st-h2 {
	display:block;
	width:100%;
	font-size: $h2-font-size;
	padding: 0 0 0.5rem 0;
	margin-bottom: 1rem;
	position:relative;
	&:after {
		content:'';
		height:2px;
		width:100%;
		position:absolute;
		bottom:0;
		left:0;
		background:$gray-200;
	}
}
.main-header-style{
	color: $st-blue;
	font-size: $h4-font-size;
	font-weight: $font-weight-bold;
	border-bottom: 1px solid $gray-200;
	@extend .mb-2;
	@extend .py-1;
}
.table {
	font-size: $font-size-base;
	thead {
		th {
			border-top: 0;
			border-bottom: 2px solid $gray-150;
			font-weight: $font-weight-bold;
			color: $gray-500;
			border-top: none;
		}
	}
	tbody {
		tr {

			&.table-item-selected {
				border: 1px solid $st-blue !important;
				background: $st-white-blue !important;
				box-shadow: 0 2px 3px $shadow-7;
			}

			td {
				vertical-align: middle;
				border-top: none;

				input[type="text"] {
					width: 60px;
				}

				&.table-item-actions {
					white-space: nowrap;

					.ms-Icon {
						margin-right: 1rem;
						font-size: $font-size-base;
						color: $gray-600;
						display: inline-block;

						&:hover {
							color: $body-color;
						}
					}
				}
			}

			&.table-success {
				background-color: $st-blue-25;
				>td, >th {
					background-color: $st-blue-25;
				}
			}
		}
	}

	&.table-dark {
		thead {
			tr {
				background: $body-color;
			}
		}

		tbody tr:hover {
			background: $body-color;
		}
	}
}




.btn-close.btn-heart{
	background: transparent escape-svg($btn-heart-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
}
.btn-close.btn-heart-solid{
	background: transparent escape-svg($btn-heart-solid-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
}

.shortstop-edit input {
	width:calc(100% - 135px - 0.25rem) !important;
}
button.shortstop-edit {
	position: relative;
    margin-bottom: -4rem;
    top: -2.65rem;
    line-height: 2rem;
    padding: 0 6px 0 6px;
    float: right;
	width:135px;
}
.no-pseudo{
	overflow-x:visible;
	&:after{
	  display: none !important;
	  width:0 !important;
	  height:0 !important;
	}
  }

button.btn-v6-dimension {
    padding-left: 6px;
    padding-right: 6px;
    width: 2em;
}

.v6-quote-item-toolbar {
  border-bottom-width: 2px;
  border-bottom-style: ridge;
  border-bottom-color: navy;
  margin-bottom: 6px;
  padding: 6px;
 	 i {
  		border-color: cadetblue;
  		color:skyblue;
		&:hover {
			color:darkblue;
			background-color: rgb(156, 217, 241);
		}
		&.disabled {
			border-color: darkgray !important;
			color:lightgray !important;
			background-color: white !important;

		}
	}

}




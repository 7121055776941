// Soft Tech colors
/*$st-blue: #3282CC;
$st-dark-blue: #1862A7;
$st-mid-blue: #62AFFC;
$st-light-blue: #BAD4EE;
$st-lighter-blue: #EBF3FB;
$st-white-blue: #f5faff;
$st-vivid-blue: #56CCF2;*/

$color-softtech: #3282CC;
$color-draft: #56CCF2;
$color-active: #F45AD2;
$color-issued: #F2994A;
$color-accepted: #27AE60;
$color-lapsed: #9B51E0;
$color-rejected: #EB5757;
$color-completed: #4F4F4F;

$base-color: $color-softtech;

$blue:    $base-color !default;
$indigo:  $color-lapsed !default;
$purple:  $color-lapsed !default;
$pink:    $color-active !default;
$red:     $color-rejected !default;
$orange:  $color-issued !default;
$yellow:  $color-issued !default;
$green:   $color-accepted !default;
$teal:    $color-accepted !default;
$cyan:    $base-color !default;

$st-blue: $base-color;

/*$st-dark-blue: adjust-color($base-color, $saturation:10%, $lightness:-15%, $hue:18);
$st-mid-blue: adjust-color($base-color, $saturation:10%, $lightness:10%, $hue:-36);*/

$st-dark-blue: adjust-color($base-color, $saturation:10%, $lightness:-15%);
$st-mid-blue: adjust-color($base-color, $saturation:10%, $lightness:10%);

$st-light-blue: adjust-color($base-color, $saturation:10%, $lightness:35%);
$st-lighter-blue: adjust-color($base-color, $saturation:10%, $lightness:40%);
$st-white-blue: adjust-color($base-color, $saturation:15%, $lightness:45%);

$st-vivid-blue: adjust-color($base-color, $saturation:30%, $lightness:35%, $hue:-20);

$st-background-1: adjust-color($base-color, $saturation:0%, $lightness:0%);
$st-background-2: adjust-color($st-vivid-blue, $saturation:0%, $lightness:-10%, $hue:0);

$st-blue-25: $st-lighter-blue;


$shadow-7: rgba(0, 0, 0, 0.07);
$shadow-10: rgba(0, 0, 0, 0.1);
$shadow-20: rgba(0, 0, 0, 0.2);
$shadow-35: rgba(0, 0, 0, 0.35);
$shadow-50: rgba(0, 0, 0, 0.5);

$white: #fff !default;
$white-25: rgba(255, 255, 255, 0.25);

$gray-100: #fafafa !default;
$gray-150: #f0f0f0 !default;
$gray-200: #ececec !default;
$gray-250: #e5e5e5 !default;
$gray-300: #dfdfdf !default;
$gray-400: #c9c9c9 !default;
$gray-500: #BDBDBD !default;
$gray-600: #999999 !default;
$gray-700: #757575 !default;
$gray-800: #666666 !default;
$gray-900: #333333 !default;
$gray-950: #222222 !default;
$gray-1000: #1e1e1e !default;
$black: #000 !default;

$font-family-icon: FabricMDL2Icons;

$font-size-base: 0.875rem !default; // Assumes the browser default, typically `14px`
$font-size-xs: 0.625rem !default;

//$body-bg: $st-lighter-blue !default;
$body-bg: $st-lighter-blue !default;
$body-color: $gray-1000 !default;


// Links
//
// Style anchor elements.

$link-color: $st-blue !default;
$link-decoration: none !default;
$link-hover-color: $st-dark-blue !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	mlg: 1368px,
	xl: 1600px,
	xxl: 1921px
) !default;
$container-max-widths: (
	xs: 100%,
	sm: 100%,
	md: 100%,
	lg: 100%,
	mlg: 100%,
	xl: 100%,
	xxl: 100%
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-row-columns: 6 !default;


$line-height-lg: 1.5 !default;
$line-height-sm: 1.25 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;


$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Sarabun", Arial, Helvetica, -apple-system, BlinkMacSystemFont, sans-serif, !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-family-icon: FabricMDL2Icons;
// stylelint-enable value-keyword-case


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( ( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3) ), $spacers );



$font-size-base: 0.875rem !default; // Assumes the browser default, typically `16px`
$font-size-xxxl: 3rem !default;
$font-size-xxl: 1.5rem !default;
$font-size-xl: 1.25rem !default;
$font-size-lg: 1rem !default;
$font-size-sm: 0.75rem !default;
$font-size-xs: 0.625rem !default;

$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 600 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 1.25 !default;
$h2-font-size: $font-size-base * 1.15 !default;
$h3-font-size: $font-size-base * 1.15 !default;
$h4-font-size: $font-size-base * 1.1 !default;
$h5-font-size: $font-size-base * 1.05 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: calc($spacer / 2) !default;
$headings-font-family: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;
$display5-size: 3rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$hr-border-color: rgba($black, .1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: .5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1rem !default;
$modal-content-bg: $white;


$btn-heart-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 217.408 217.408'><path d='M194.078,22.682c-10.747-8.193-22.606-12.348-35.248-12.348c-15.951,0-33.181,6.808-50.126,19.754 C91.759,17.142,74.529,10.334,58.578,10.334c-12.642,0-24.501,4.155-35.248,12.348C7.606,34.671-0.24,49.8,0.006,67.648 c0.846,61.117,100.093,133.233,104.317,136.273l4.381,3.153l4.381-3.153c4.225-3.04,103.472-75.156,104.317-136.273 C217.648,49.8,209.802,34.671,194.078,22.682z M153.833,149.017c-18.374,18.48-36.915,33.188-45.129,39.453 c-8.214-6.265-26.755-20.972-45.129-39.453c-31.479-31.661-48.274-59.873-48.57-81.585c-0.178-13.013,5.521-23.749,17.421-32.822 c8.073-6.156,16.872-9.277,26.152-9.277c17.563,0,34.338,10.936,45.317,20.11l4.809,4.018l4.809-4.018 c10.979-9.174,27.754-20.11,45.317-20.11c9.28,0,18.079,3.121,26.152,9.277c11.9,9.073,17.599,19.809,17.421,32.822 C202.107,89.145,185.311,117.356,153.833,149.017z'/></svg>") !default;
$btn-heart-solid-bg:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 217.408 217.408'><path fill='#3282CC' d='M194.1,22.7c-27.4-20.4-57.2-13.8-85.3,7.5c-28.3-21.5-57.9-28-85.4-7.6c-81.3,58.9,73,177.8,85.3,184.5 c0,0,4.4-3.2,4.4-3.2C123.1,200.2,274.9,77,194.1,22.7z'/></svg>") !default;


$table-striped-bg:            $gray-150 !default;
$table-active-bg:             $gray-300 !default;
$table-hover-bg:              $st-white-blue !default;

@import "st_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300&display=swap');
@import "st_mixins.scss";
@import "st_ui.scss";
@import "dashboard_charts.scss";


/*  Legacy Stuff */
#routerOutlet > * {
    width: 90% !important;
}
.bigger-select select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
/*  New Stuff */
.footer{
  @include gradient-x($st-mid-blue, $st-dark-blue);
  color: $white;
  font-size: $font-size-sm;
  .st-logo{
    img{
      height:2rem;
    }
  }
}
.navbar-header {
  color: $st-blue;
  background: $white;
}
header{
  .navbar{
    padding:0.5rem 0;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    .navbar-brand{
      @extend .py-0;
    }
    .navbar-text{
      @extend .py-3;
      display: block;
      @include media-breakpoint-up(lg){
        display: inline-block;
      }
    }
    .navbar-nav{
      .nav-item{
        .nav-link{
          padding: 0.5rem 0;
          color: $body-color;
          font-weight: $font-weight-bold;
          @include media-breakpoint-up(lg) {
            font-size: $font-size-lg;
            padding:$spacer 0.5rem;
          }
          @include media-breakpoint-up(xl) {
            padding:$spacer 1.5rem;
          }
        }
        &.active{
          .nav-link{
            background: $st-lighter-blue;
            cursor:default;
            @include media-breakpoint-up(lg) {
              border-top-left-radius: 0.5rem;
              border-top-right-radius: 0.5rem;
            }
          }
        }
      }
    }
    .navbar-collapse{
      position: absolute;
      background: $st-lighter-blue;
      left: 0;
      padding: 0 $spacer;
      width: 100%;
      top: 100%;
      z-index: 10;
      box-shadow: 0 10px 10px $shadow-10;
      @include media-breakpoint-up(lg) {
        position: relative;
        padding:$spacer 0 0 0;
        background: $white;
        box-shadow: none;
      }
    }
  }
}
.navbar-toggler{
  border: 0!important;
  box-shadow: none !important;
  position: relative;
  display: block;
  height:$spacer;
  &:before,
  &:after,
  >.navbar-toggler-icon{
    content: '';
    display: block;
    position: absolute;
    height:2px;
    width:100%;
    background: $st-blue;
    left:0;
  }
  &:before{
    top:0;
  }
  &:after{
    bottom:0;
  }
  >.navbar-toggler-icon{
    top:calc(50% - 1px);
  }
}
.navbar-brand{
  color: $base-color;
  img{
    width: auto;
    max-height: 42px;
    max-width: 140px;
    object-fit: contain;
    @include media-breakpoint-up(lg) {
      height:60px;
      max-height:unset;
    }
  }
}

.btn-close-message {
    box-sizing: content-box;
    opacity: 0.6;
    color: green;
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(var(--bs-modal-header-padding-x) * 0.5) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-header .btn-close {
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x));
}

.nav-fill,
.nav-tabs{
  position: relative;
  border-bottom: none;
  > .nav-link,
  .nav-item{
    flex: 0 0 100%;
    margin-right:3px;
    &.page-nav-buttons{
      @include flex-container(row, wrap, space-between, center, center);
      .page-nav-buttons-left,
      .page-nav-buttons-right{
        @include flex-element(0, 0, 0, auto);
        @include flex-container(row, wrap, flex-start, flex-start, flex-start);
        text-align:left;
      }
      .page-nav-buttons-right{
        @include flex-container(row-reverse, wrap, flex-start, flex-start, flex-start);
      }
      .page-nav-buttons-left{
        margin-bottom:1rem;
      }
      button,
      .btn,
      .btn-group{
        @include flex-element(0, 0, 0, auto);
      }
    }
    @include media-breakpoint-up(sm) {
      flex: 0 0 auto;
      &.page-nav-buttons{
        margin:0 !important;
        position: absolute;
        right:0;
        top:0;
        .page-nav-buttons-right{
          @include flex-container(row-reverse, wrap, flex-end, flex-start, flex-start);
          text-align:right;
        }
        button,
        .btn,
        .btn-group{
          margin: 0 0 0 3px;
        }
      }
    }
    @include media-breakpoint-up(md) {
      &.page-nav-buttons{
        .page-nav-buttons-left{
          margin-bottom:0;
        }
      }
    }
  }
  .nav-link{
    background: $st-blue;
    color: $white;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    padding:0.75rem 2.25rem 0.75rem 1rem;
    font-weight: $font-weight-bold;
    @include flex-container(column, nowrap, flex-start, flex-start, flex-start);
    height:100%;
    text-align: left;
    line-height: 1.1;
    position: relative;
    .tab-value{
      font-size: $font-size-lg;
      line-height: 1.1;
      font-weight: $font-weight-bolder;
      margin-top:0.25rem;
      white-space: nowrap;
      max-height: 20px;
    }
    >*{
      @include flex-element(0, 0, 0, 100%);
    }
    &:hover {
        background: $st-dark-blue;
    }

    &.active {
        background: $white;
        color: $st-blue;
        cursor:default;
        .tab-value{
          color:$body-color;
        }
    }
    @include media-breakpoint-up(lg) {
      @include gradient-y($st-blue, $st-dark-blue, 85%, 100%);
      min-width: 120px;
    }
    .btn-close{
      background-size: contain;
      width:0.75rem;
      height:0.75rem;
      padding:0;
      display: block;
      position: absolute;
      right: 1rem;
      top: 0.9rem;
    }
  }
}
.modal{
  .nav-fill,
  .nav-tabs{
    .nav-link{
      &.active {
          background: $white;
          color: $st-blue;
          cursor:default;
      }
    }
  }
}
.pagination-box{
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-end;
  @extend .align-items-center;
  @extend .align-self-center;
  @extend .mt-3;
  ul{
    @extend .mb-0;
  }
}
#main-body >* >.page-content{
  padding:1rem;
  padding-bottom:calc(34px + 2rem);
  position: relative;
}
.page-container{
  .tab-content{
    @include whitebox();
    padding-bottom:0;
    border-bottom:1rem solid $white;
    height: calc(100vh - 12.5rem - 45px); /*To review values on 80% ready*/
    overflow-y: auto;
    @include media-breakpoint-up(md) {
        height: calc(100vh - 12.5rem - 45px);
    }
    @include media-breakpoint-up(lg) {
        height: calc(100vh - 13rem - 34px);
    }
  }
  //Quotes and PO List Page. Commented out because of removed price in the tabs
  /*.page-content[id^="quote-list-view"],
  .page-content[id^="purchase-order-list-view"]{
    .tab-content{
      height: calc(100vh - 12.5rem - 68px);
      @include media-breakpoint-up(md) {
          height: calc(100vh - 12.5rem - 68px);
      }
      @include media-breakpoint-up(lg) {
          height: calc(100vh - 13rem - 55px);
      }
    }
  }*/
}
#main-body div.dataTables_scrollBody{
  max-height:calc(100vh - 435px) !important;
  border-bottom:1px solid $gray-200;
  &.webmodule-filter-enabled{
    max-height:calc(100vh - 435px - 50px) !important; //50px - filter height
  }
  @include media-breakpoint-up(md) {
    max-height:calc(100vh - 365px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 365px - 50px) !important;
    }
  }
  @include media-breakpoint-up(lg) {
    max-height:calc(100vh - 370px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 370px - 50px) !important;
    }
  }
  @include media-breakpoint-up(xl) {
    max-height:calc(100vh - 370px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 370px - 50px) !important;
    }
  }
}
//Quotes List Page
#main-body .page-content[id^="quote-list-view"] div.dataTables_scrollBody{
  max-height:calc(100vh - 455px) !important;
  &.webmodule-filter-enabled{
    max-height:calc(100vh - 455px - 50px) !important;
  }
  @include media-breakpoint-up(md) {
    max-height:calc(100vh - 385px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 385px - 50px) !important;
    }
  }
  @include media-breakpoint-up(lg) {
    max-height:calc(100vh - 390px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 390px - 50px) !important;
    }
  }
  @include media-breakpoint-up(xl) {
    max-height:calc(100vh - 390px) !important;
    &.webmodule-filter-enabled{
      max-height:calc(100vh - 390px - 50px) !important;
    }
  }
}
.form-control,
.form-select{
  background-color: $white;
  background-position: right 0.5rem center;
}

textarea.form-control {
  height: 118px;
}

.quoteItemImageWrapper{
  .quoteItemImage{
    text-align: center;
    height:100%;
    svg{
      max-height: 160px !important;
      .overlay{
        fill: $st-blue !important;
        fill-opacity: 0.35 !important;
      }
    }
    @include media-breakpoint-up(lg) {
      svg{
        max-height: calc(100vh - 470px) !important;
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 0 4rem;
    }
  }
  .quoteItemImageText{
    text-align: center;
  }
}
.frame-treeview,
.graphical-picker-body{
  ol, ul{
    padding:0;
    margin:0;
    list-style: none;
    display: block;
    li{
      margin:0;
      padding:0;
      display: block;
      a{
        position: relative;
        display: block;
        margin:0;
        padding:0.5rem $spacer;
        padding-left:1.5rem;
        border:1px solid $white;
        //border-bottom:1px solid $gray-200;
        background: $white;
        &:before{
          content: '';
          position: absolute;
          z-index: 1;
          display: block;
          left: 0.5rem;
          top:calc(50% - 0.25rem);
          height:0.35rem;
          width:0.35rem;
          border-right:1px solid $st-blue;
          border-bottom:1px solid $st-blue;
          @include rotate(45deg);
        }
        &:hover{
          border:1px solid $gray-150;
          background: $gray-100;
        }
      }
      &.v6-frame-navigation-active,
      &.v6-family-navigation-active{
        >a{
          background: $st-lighter-blue;
          font-weight: $font-weight-bold;
        }
      }
      ol, ul{
        li{
          a{
            margin-left:0.5rem;
          }
          ol, ul{
            position: relative;
            &:before{
              content: '';
              position: absolute;
              z-index: 1;
              display: block;
              left:1.25rem;
              top:0;
              background: $gray-200;
              height: 100%;
              width:1px;
            }
            li{
              a{
                margin-left:calc(1.25rem + 1px);
                &:before{
                  @include rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
.quoteItemFrameNav{
  >h4{
    @extend .mb-3;
  }
}

.quoteItemFrameOptions,
.quoteItemFrameProperties{
  >h4{
    @extend .mb-3;
    .subheader{
      font-size: $font-size-sm;
      color: $gray-600;
      font-weight: $font-weight-normal;
    }
  }
  .attributeContents{
    .v6config-group{
      .v6config-group-header{
        @extend .main-header-style;
      }
      .v6config-attribute {
        min-height:2rem;
        position: relative;
        @extend .align-items-center;
        .v6config-attribute-input{
          .quote-default-validation{
            right: 3rem;
            position: absolute;
            top: 0.6rem;
          }
        }
      }
    }
  }
}

.spinner-border{
  width: $spacer;
  height: $spacer;
  border-width: 2px;
}

.graphical-picker-card-container,
.graphical-picker-card-family-container{
  >.card{
    height:100%;
    .card-body{
      position: relative;
      cursor: pointer;
      img{
        object-fit: contain;
        max-height:112px;
        margin-bottom: 0.5rem;
      }
      .btn-close,
      .btn-fav{
        position: absolute;
        right:0.5rem;
        top:0.5rem;
        width:0.5rem;
        height:0.5rem;
      }
    }
  }
}
.graphical-picker-card-family-container{
  >.card{
    .card-body{
      padding:0;
      img{
        object-fit: cover;
        margin-bottom: 0;
        max-height: unset;
        // To be fixed after Demo as we can't guarantee 16:9 proportions for all images
        //max-height:200px;

        /*@include media-breakpoint-up(lg) {
          max-height:240px;
        }
        @include media-breakpoint-up(xl) {
          max-height:280px;
        }*/
      }
      .card-title{
        position: absolute;
        z-index: 1;
        width:100%;
        left:0;
        bottom:0;
        margin:0;
        padding:0.5rem $spacer;
        background: $white;
        font-size: $font-size-lg;
      }
    }
  }
}
.push-right {
    position: relative;
    top: -2rem;
    right: -2.5rem;
    margin-bottom:-2rem;
}
.frame-history-card{
  border: none;
  border-radius: 0;
  .row{
    margin:0 !important;
    --bs-gutter-x:0;
  }
  .card-body{
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .align-self-stretch;
    border-bottom:1px solid $gray-200;
  }
  .graphical-picker-img{
    object-fit: contain;
    width: 26px;
    height:26px;
  }
  .btn-close{
    background-color: $white;
    width:0.3rem;
    height:0.3rem;
  }
}
.v6-quote-item-view{
  .v6-quote-item-view-header{
    @extend .mb-3;
    h2{
      @extend .main-header-style;
    }
  }
}
.quote-item-view-body{
  .quoteItemFrameNav{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-6;
    @extend .col-lg-4;
    @extend .col-mlg-3;
    @extend .order-1;
  }
  .quoteItemFrameOptions{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-6;
    @extend .col-lg-4;
    @extend .col-mlg-3;
    @extend .order-1;
  }
  .quoteItemImageWrapper{
    @include media-breakpoint-down(lg) {
      width:100%;
    }
    @extend .col-lg-4;
    @extend .col-mlg-6;
    @extend .order-0;
    @extend .order-lg-1;
  }
}
.filter-wrapper{
  @extend .mb-3;
  @extend .justify-content-end;
  @extend .align-items-center;
  @extend .align-self-center;
  .filter-text{
    text-align: right;
    @include media-breakpoint-down(md) {
      width:100%;
      text-align: left;
    }
    @extend .col-md-1;
  }
  .filter-input{
    @include media-breakpoint-down(md) {
      width:100%;
    }
    @extend .col-md-4;
    @extend .col-lg-4;
    @extend .col-xl-3;
  }
}
.graphical-picker-body{
  .graphical-picker-tree{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-4;
    @extend .col-md-3;
    @extend .col-lg-2;
    background: $white;
    padding-top:$spacer;
  }
  .graphical-picker-list-wrapper{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-8;
    @extend .col-md-9;
    @extend .col-lg-10;
    &.no-family{
      width:100%;
    }
  }
  .graphical-picker-list{
    @extend .d-flex;
    @extend .justify-content-start;
    @extend .align-items-stretch;
    @extend .align-self-stretch;
  }
  .graphical-family-picker-list{
    margin-bottom: 2rem;
  }
  .graphical-picker-card-container{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .col-lg-3;
    @extend .col-xl-2;
    @extend .mb-3;
  }
  .graphical-picker-card-family-container{
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-12;
    @extend .col-md-6;
    @extend .col-lg-4;
    @extend .col-xl-4;
    @extend .mb-3;
  }
}
.qty-badge{
  position: relative;
  display: inline-block;
  padding-left:0.25rem;
}
.finish-color-block{
  height:1.5rem;
  width:3rem;
  border-radius:0.25rem;
}
.dataTables_scrollHeadInner{
  min-width: 100%; // !!! Alex S. To remove on fixing the table width with the recalculating width with JS
}
.tab-pane{
  height:100%;
  >div{
    height:100%;
    >.dataTables_wrapper{
      height:100%;
      >.row:nth-of-type(2){
        height:calc(100% - 50px);
        >div{
          height:100%;
        }
      }
    }
    >.filter-wrapper + .dataTables_wrapper{
      height:calc(100% - 50px);
      >.row:nth-of-type(2){
        height:calc(100% - 50px);
      }
    }
  }
}
table.dataTable,
.table-responsive > table{
  width:100% !important; // !!! Alex S. To remove on fixing the table width with the recalculating width with JS
  margin-top:0!important;
  overflow: auto;
  height:100%;
  @include flex-container(row, wrap, flex-start, flex-start, flex-start);
  @include flex-element(0, 1, 1, 100%);
  position: relative;
  > :not(:first-child){
    border-top:0;
  }
  >thead{
    border:0;
    @include flex-container(row, wrap, flex-start, flex-start, flex-start);
    @include flex-element(0, 1, 1, 100%);
    position: sticky;
    left:0;
    top:0;
    z-index: 2;
    tr{
      border:0;
      @include flex-container(row, nowrap, flex-start, center, flex-start);
      @include flex-element(0, 1, 1, 100%);
      th{
        @include flex-element(0, 1, 1, 100%);
        background: $st-blue;
        border:0;
        border-left: 1px solid $white;
        color: $st-vivid-blue;
        padding: 0.5rem 0.5rem;
        white-space: nowrap;
        &.sorting_desc,
        &.sorting_asc{
          color: $white;
          background-color: $st-dark-blue;
        }
        &:before,
        &:after{
          color:$white;
        }
        &:not(.sorting_disabled){
          padding-right:0.5rem;
        }
        &:nth-last-of-type(1){
          padding-right:calc(0.5rem + 17px); //fix for scrollbar overlay
        }
        @include media-breakpoint-up(mlg) {
          padding: 0.75rem 0.5rem;
          &.sorting:before,
          &.sorting:after,
          &.sorting_asc:before,
          &.sorting_asc:after,
          &.sorting_desc:before,
          &.sorting_desc:after,
          &.sorting_asc_disabled:before,
          &.sorting_asc_disabled:after,
          &.sorting_desc_disabled:before,
          &.sorting_desc_disabled:after{
            bottom:0.85em;
          }
        }
      }
    }
  }
  >tbody{
    border:0;
    @include flex-container(row, wrap, flex-start, flex-start, flex-start);
    @include flex-element(0, 1, 1, 100%);
    position: relative;
    overflow-y: auto;
    tr{
      border:0;
      --bs-table-accent-bg:unset !important;
      background: $white;
      @include flex-container(row, nowrap, flex-start, center, flex-start);
      @include flex-element(0, 1, 1, 100%);
      border-bottom:1px solid $gray-300!important;
      td{
        @include flex-element(0, 1, 1, 100%);
        --bs-table-accent-bg:unset !important;
        border:0 !important;
        white-space: nowrap;
        position: relative;
        overflow-x: hidden;
        padding: 0.5rem 0.5rem;
        &:nth-last-of-type(1){
          padding-right:calc(0.5rem + 17px); //fix for scrollbar overlay
        }
        &:after{
          content:'';
          display: block;
          position: absolute;
          right:0;
          top:0;
          z-index: 1;
          width:32px;
          height:100%;
          background: url(../assets/images/table_grad_w.png) repeat-y top right;
        }
        &.odd,
        &.even{
          border-bottom:1px solid $st-blue !important;
        }
      }
      &:hover{
          background: $st-blue-25 !important;
          box-shadow: 0 2px 3px $shadow-7;
        td{
          &:after{
            background: url(../assets/images/table_grad_b.png) repeat-y top right;
          }
        }
      }
      &.odd {
        background: $gray-200;
        td{
          background: transparent !important;
          &:after{
            background: url(../assets/images/table_grad_g.png) repeat-y top right;
          }
        }
        &:hover{
            background: $st-blue-25 !important;
          td{
            &:after{
              background: url(../assets/images/table_grad_b.png) repeat-y top right;
            }
          }
        }
      }
      &.even{
        background: $white;
        td{
          background: transparent !important;
        }
        &:hover{
            background: $st-blue-25 !important;
          td{
            &:after{
              background: url(../assets/images/table_grad_b.png) repeat-y top right;
            }
          }
        }
      }
    }
  }
}
div.dataTables_scrollBody{
  border-left:0 !important;
}
.dataTables_wrapper {
	font-size: $font-size-sm;
	position: relative;
  .dataTables_info{
    text-align: left;
    margin-top:$spacer !important;
  }
	.dataTables_paginate {
		text-align: right;
    margin-top:$spacer !important;
		.paginate_button{
      &.page-item{
        margin-left:2px;
        .page-link{
          border:0;
          padding:0 0.5rem;
          min-width:28px;
          min-height:28px;
          line-height: 28px;
          text-align: center;
          background: $gray-150;
          border:1px solid $gray-200;
          color:$body-color;
          z-index: 0;
        }
        &.active{
          > .page-link{
            background: $white;
          }
        }
      }
      &.previous,
      &.next{
        font-size: 0;
        > .page-link{
          background-color: $white;
          border-color:$white;
          &:after {
            @include icon-font(16px);
            content: '';
            vertical-align: middle;
          }
        }
        &.disabled > .page-link{
          cursor: default;
          background-color: $white;
          &:after {
            opacity: 0.33;
          }
        }
      }
      &.previous{
        > .page-link:after {
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8001 24.2L0.100098 12.5L11.8001 0.800049L13.2001 2.19995L2.90009 12.5L13.2001 22.8L11.8001 24.2Z' fill='%233282CC'/%3E%3C/svg%3E%0A");
        }
        &.disabled {
          > .page-link:after {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8001 24.2L0.100098 12.5L11.8001 0.800049L13.2001 2.19995L2.90009 12.5L13.2001 22.8L11.8001 24.2Z' fill='%23999999'/%3E%3C/svg%3E%0A");
          }
        }
      }
      &.next{
        > .page-link:after{
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.50009 24.2L0.100098 22.8L10.4001 12.5L0.100098 2.19995L1.50009 0.800049L13.2001 12.5L1.50009 24.2Z' fill='%233282CC'/%3E%3C/svg%3E%0A");
        }

        &.disabled {
          > .page-link:after {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.50009 24.2L0.100098 22.8L10.4001 12.5L0.100098 2.19995L1.50009 0.800049L13.2001 12.5L1.50009 24.2Z' fill='%23999999'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
	}
}
.badge{
  color: $white;
  padding:0.25rem 0.75rem;
  font-size: $font-size-sm;
  line-height: $line-height-base;
}
.modal{
  .modal-dialog{
    @include flex-container(row, wrap, center, center, center);
    height:100%;
    margin: 0 auto !important;
    padding:$spacer;
    @include media-breakpoint-up(xl) {
      padding:3rem;
    }
    .modal-content{
      border-radius:1rem !important;
      border:none !important;
    }
    .ModalColAttributeContents{
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 75vh;
    }
    .modal-header{
      .modal-title{
        font-size: $font-size-xl;
        @include flex-container(row, wrap, flex-start, center, flex-start);
        >*{
          @include flex-element(0, 0, 0, auto);
        }
        .badge{
          margin-left:1rem;
          margin-right:1rem;
          margin-top:2px;
        }
        .title-links{
          @include flex-element(0, 0, 0, auto);
          font-size: $font-size-sm;
          font-weight: $font-weight-normal;
          margin-top:2px;
          .btn{
            padding:0.25rem 0.75rem;
            font-size: $font-size-sm;
            margin-right:1rem;
            line-height: $line-height-base;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
    .modal-footer{
      background: $body-bg;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border:none !important;
    }
    &.modal-fullscreen{
      .modal-footer{
        display: none;
        border-radius: 1rem !important;
      }
      .modal-content{
        border-radius:1rem !important;
      }
      .modal-body{
        background: $body-bg;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
      .nav-fill,
      .nav-tabs{
        position: static;
        > .nav-link,
        .nav-item{
          &.active {
              background: $white;
              color: $body-color;
              cursor:default;
          }
          &.page-nav-buttons{
            position: absolute;
            z-index: 1;
            bottom:0;
            left:0;
            top:auto;
            width:100%;
            padding:$spacer;
            background: $body-bg;
            .btn-group{
              .dropdown-menu{
                width:100%;
                transform: translate(0px, -34px) !important;
              }
            }
          }
        }
      }
      .page-control-tab-content{
        height:calc(100vh - 127px - 66px - 39px); //- modal header - modal footer height - tabs
        overflow-y: hidden;
        overflow-x: hidden;
        padding:$spacer;
        background: $white;
        @include media-breakpoint-up(xl) {
          height:calc(100vh - 190px - 66px - 39px);
        }
        .tab-pane{
          height:100%;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      @include media-breakpoint-up(lg) {
        .quoteItemFrameNav,
        .quoteItemFrameOptions{
          height:calc(100vh - 240px - 74px);
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      @include media-breakpoint-up(xl) {
        .quoteItemFrameNav,
        .quoteItemFrameOptions{
          height:calc(100vh - 297px - 74px);
        }
      }
    }
  }
}
#main-page #main-body{
  .nav-fill,
  .nav-tabs{
    position: static;
    > .nav-link,
    .nav-item{
      &.page-nav-buttons{
        position: absolute;
        z-index: 1;
        top:calc(100% - 34px - 2rem);
        left:1rem;
        right:1rem;
        width:calc(100% - 2rem);
        padding: 1rem 0;
      }
    }
  }
}
.section-header{
  @extend .main-header-style;
}
.form-two-col,
.form-one-col{
  >h2,
  >.row >h2,
  >.row >div >h2{
    @extend .main-header-style;
  }
  >.row{
    @extend .d-flex;
    @extend .justify-content-start;
    @extend .align-items-stretch;
    @extend .align-self-stretch;
    >div{
      .form-col-item{
        .form-col-label{
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          margin-bottom: 0.25rem;
          @include media-breakpoint-up(sm) {
            margin-bottom:0;
          }
        }
        .form-col-input{
        }
      }
    }
  }
}
.form-switch{
  padding-left:$spacer;
  .form-check-input{
    margin-left:0;
    margin-right:0.5rem;
    height:1.5rem;
    width:3rem;
  }
  .form-check-label{
    margin-top: 0.25rem;
  }
}
.form-two-col{
  >.row{
    >div{
      @include media-breakpoint-down(md) {
        width:100%;
      }
      @extend .col-md-6;
      .form-col-item{
        .form-col-label{
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          @extend .col-sm-2;
          @extend .col-md-3;
          @extend .col-xl-2;
        }
        .form-col-input{
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          @extend .col-sm-10;
          @extend .col-md-9;
          @extend .col-xl-10;
        }
      }
    }
  }
  .form-switch{
    @extend .offset-sm-2;
    @extend .offset-md-3;
    @extend .offset-xl-2;
    @extend .col-sm-10;
    @extend .col-md-9;
    @extend .col-xl-10;
  }
}
.form-one-col{
  >.row{
    >div{
      @include media-breakpoint-down(md) {
        width:100%;
      }
      .form-col-item{
        .form-col-label{
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          @extend .col-sm-5;
          @extend .col-md-4;
          @extend .col-xl-3;
        }
        .form-col-input{
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          @extend .col-sm-7;
          @extend .col-md-8;
          @extend .col-xl-9;
        }
      }
    }
  }
  .form-switch{
    @extend .offset-sm-5;
    @extend .offset-md-4;
    @extend .offset-xl-3;
  }
}
.page-control-tab-content{
  padding:$spacer 0 0 0;
}
td > .fa-solid{
  color: $st-blue;
}

.page-item.active .page-link{
  background-color: $st-blue;
  border-color: $st-blue;
}

// Google auto complete container
.pac-container {
  z-index: 10000 !important;
}
.modal-fullscreen-login{
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  padding:0 !important;
  border: none !important;
  .modal-content{
      height: 100%;
      border: 0 !important;
      border-radius: 0;
      box-shadow: none !important;
  }
  .modal-header,
  .modal-footer{
    display: none !important;
  }
}

.login-page {
	background: $white;

	@include media-breakpoint-up(md) {
		height: calc(100vh - 76px);
		min-height: 620px;
	}

	@include media-breakpoint-up(lg) {
		height: calc(100vh - 58px);
	}

	.modal-body {
		@include flex-container(row, wrap, flex-start, stretch, stretch);
		padding: 0;
		height: 100%;
		position: relative;

		.login-page-image {
      background: $st-lighter-blue;
			@include flex-element(0, 0, 0, 100%);

			@include media-breakpoint-up(md) {
				@include flex-element(0, 0, 0, 66.666666%);
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				@include object-fit(cover);
				max-height: 140px;
				filter: brightness(1.2) contrast(0.8) blur(1px) saturate(0.3) opacity(0.5);

				@include media-breakpoint-up(sm) {
					max-height: 240px;
					filter: none;
				}

				@include media-breakpoint-up(md) {
					max-height: 100%;
				}
			}
		}

		.page-content {
			@include flex-element(0, 0, 0, 100%);
			@include flex-container(row, wrap, center, center, center);
			padding: 2rem $spacer;
			margin: 0 auto;
			max-width: 240px;

			@include media-breakpoint-up(md) {
				@include flex-element(0, 0, 0, 33.333333%);
				padding: 2rem $spacer;
			}

			@include media-breakpoint-up(lg) {
				@include flex-element(0, 0, 0, 33.333333%);
				padding: 4rem $spacer;
			}

			@include media-breakpoint-up(xl) {
				max-width: 360px;

				.form-actions button {
					max-width: 180px;
					margin: 0 auto;
					display: block;
				}
			}

			> * {
				@include flex-element(0, 0, 0, 100%);
			}

			.dealer-logo {
				margin-bottom: $spacer;
        text-align: center;
				@include media-breakpoint-up(xl) {
					margin-bottom: 4rem;
				}

				img {
					display: block;
					width: 100%;
					max-width: 250px;
					height: auto;
					margin: 0 auto;
				}
			}

			.supplier-logo {
				position: absolute;
				left: auto;
				top: 2rem;

				img {
					display: block;
					width: 100%;
                    max-width: 258px;
					height: auto;
					margin: 0 auto;
				}

				@include media-breakpoint-up(md) {
					left: 2rem;
				}
			}

			.login-page-content {
				text-align: center;

				> h2 {
					font-weight: $font-weight-light;
					color: $st-blue;
					margin-bottom: 2rem;
					font-size: 2.5rem;
				}

				> .login-page-content-text {
					margin: $spacer 0;
				}

				> #account {
					.form-item {
						text-align: left;
						margin-bottom: $spacer;

						label {
							font-weight: $font-weight-bold;
							margin: 0;
						}

						&.checkbox-field {
							@include flex-container(row, wrap, flex-start, center, center);

							> * {
								@include flex-element(0, 0, 0, auto);
							}

							label {
								order: 1;
								font-weight: $font-weight-normal;
							}

							input {
								margin-right: 0.5rem;
								font-size: $font-size-base;
							}
						}
					}

					.form-actions {
						margin: 2rem 0;

						button {
							width: 100%;
						}
					}

					.checkbox {
						text-align: left;

						input {
							margin-right: 0.5rem;
						}
					}
				}

				.password-strength {
					margin: 2rem 0;
					color: $gray-600;

					.password-strength-message {
						text-align: left;
						margin: 0 0 0.25rem 0;

						span {
							color: $gray-600;
							font-weight: $font-weight-bold;
						}
					}

					.password-strength-bar {
						width: 100%;
						height: 6px;
						border: 1px solid $gray-200;
						position: relative;

						&:before {
							content: '';
							display: block;
							position: absolute;
							width: 0;
							background: $gray-200;
							left: 0;
							top: 0;
							height: 4px;
						}
					}

					&.password-strong {
						.password-strength-message {
							span {
								color: $green;
							}
						}

						.password-strength-bar {
							&:before {
								width: 90%;
								background: $green;
							}
						}
					}

					&.password-weak {
						.password-strength-message {
							span {
								color: $red;
							}
						}

						.password-strength-bar {
							&:before {
								width: 25%;
								background: $red;
							}
						}
					}

					&.password-good {
						.password-strength-message {
							span {
								color: $st-blue;
							}
						}

						.password-strength-bar {
							&:before {
								width: 60%;
								background: $st-blue;
							}
						}
					}
				}
			}
		}
	}
}
.image-upload-image{
  .image-upload-wrapper{
    border:1px dashed $gray-300;
    padding:1rem;
    text-align:center;
    background: url(../assets/images/transparent-bg.png) repeat center center;
    height:240px;
    @include flex-container(row, wrap, center, center, center);
    @include media-breakpoint-up(md) {
      height:220px;
    }
    @include media-breakpoint-up(lg) {
      height:300px;
    }
    @include media-breakpoint-up(xl) {
      height:420px;
    }
  }
  img{
    @include flex-element(0, 0, 0, 100%);
    width:100%;
    max-width:100%;
    max-height:100%;
    object-fit:contain
  }
}
.multi-action-btn {
	display: inline-flex;
	font-family: 'Roboto', sans-serif;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
	padding: 6px 20px;
	font-size: 14px;
	background-color: $white;
	color: $body-color;
	text-transform: uppercase;
	border: none;
	border-radius:0;
	transition: all 0.2s ease-in;
	background-position: 50%;
	outline: none !important;

	&.btn-circle {
		height: 37px;
		width: 40px;
		padding: 0 !important;
		font-size: 18px;
	}
}

.multi-actions {
	width: 40px;

	input {
		display: none;

		&:not(:checked) {
			~ label {
				transform: rotate(0);
				box-shadow: none;

				.multi-action-btn {
					border-radius: 0;
					height: 0px;
					width: 0px;
					box-shadow: none;
					opacity: 0;

					&:nth-last-child(1) {
						transform: translate(20px, 20px);
					}
          &:nth-last-child(2) {
						transform: translate(0, 20px);
					}
          &:nth-last-child(3) {
						transform: translate(-20px, 20px);
					}
          &:nth-last-child(4) {
						transform: translate(-40px, 20px);
					}
          &:nth-last-child(5) {
						transform: translate(-60px, 20px);
					}
          &:nth-last-child(6) {
						transform: translate(-80px, 20px);
					}
					.icon {
						opacity: 0;
						width: 0;
						height: 0;
					}
				}

				> .icon {
					opacity: 0.8;
					cursor: pointer;
				}
			}
		}
	}

	label {
		position: relative;
		height: 37px;
		width: 40px;
		display: block;
		margin-bottom: 0;
		transition: all .2s ease-in;
		cursor: default;

		.multi-action-btn {
			position: absolute;
			z-index: 1;
			border-top: 1px solid $gray-300;
			border-bottom: 1px solid $gray-300;
      border-left:1px solid $gray-300;
			opacity: 1;

      &:nth-last-child(1) {
				transform: translate(0, 0);
				transition: all .8s ease-in-out;
        border-right:1px solid $gray-300;
			}
			&:nth-last-child(2) {
				transform: translate(-20px, 0);
				transition: all .10s ease-in-out;
        border-right:1px solid $gray-300;
			}
      &:nth-last-child(3) {
				transform: translate(-60px, 0);
				transition: all .10s ease-in-out;
			}
      &:nth-last-child(4) {
				transform: translate(-100px, 0);
				transition: all .12s ease-in-out;
			}
      &:nth-last-child(5) {
				transform: translate(-140px, 0px);
				transition: all .14s ease-in-out;
			}
      &:nth-last-child(6) {
				transform: translate(-180px, 0px);
				transition: all .16s ease-in-out;
			}
			.icon {
				width: 28px;
				height: 28px;
				opacity: 0.8;
				transition: all .10s ease-in-out;
			}

			&:hover {
				.icon {
					opacity: 1;
				}
			}
		}

		> .icon {
			color: $body-color;
			position: absolute;
			top: 0;
			display: flex;
			font-size: 30px;
			align-items: center;
			justify-content: center;
			height: 37px;
			width: 40px;
			opacity: 0;
			transition: all .25s ease-in-out;
			cursor: pointer;
		}

		.icon {
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}

	&.quote-multi-actions {
		position: absolute;
		right: 0;
		top: 2px;

		input {
			&:not(:checked) {
				~ label {
					.multi-action-btn {
						&:first-child {
							transform: translate(-20px, 20px);
						}

						&:nth-child(2) {
							transform: translate(0, 20px);
						}

						&:nth-child(3) {
							transform: translate(20px, 20px);
						}
					}
				}
			}
		}

		label {
			.multi-action-btn {
				position: absolute;
				z-index: 1;
				border-top: 1px solid $gray-300;
				border-bottom: 1px solid $gray-300;
				opacity: 1;

				&:first-child {
					border-left: 1px solid $gray-300;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					transform: translate(-40px, 0);
					transition: all .10s ease-in-out;
				}

				&:nth-child(2) {
					border-left: 1px solid $gray-300;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					transform: translate(-80px, 0);
					transition: all .10s ease-in-out;
				}

				&:nth-child(3) {
					transform: translate(0, 0);
					transition: all .10s ease-in-out;
					border-right: 1px solid $gray-300;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
					border-left: 1px solid $gray-300;
				}
			}
		}
	}

	&.project-multi-actions {
		position: absolute;
		right: 0;
		top: 2px;

		input {
			&:not(:checked) {
				~ label {
					.multi-action-btn {
						&:first-child {
							transform: translate(-20px, 20px);
						}

						&:nth-child(2) {
							transform: translate(0, 20px);
						}
					}
				}
			}
		}

		label {
			.multi-action-btn {
				position: absolute;
				z-index: 1;
				border-top: 1px solid $gray-300;
				border-bottom: 1px solid $gray-300;
				opacity: 1;

				&:first-child {
					border-left: 1px solid $gray-300;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					transform: translate(-40px, 0);
					transition: all .10s ease-in-out;
				}

				&:nth-child(2) {
					border-left: 1px solid $gray-300;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					transform: translate(0px, 0);
					transition: all .10s ease-in-out;
				}
			}
		}
	}
}
.quote-price-summary > .row {
  justify-content: space-between !important;
  .quote-items-terms {
    @include flex-element(0, 0, 0, 100%);
    textarea {
      width: 100% !important;
      min-height: 200px;
    }
    label {
      width:100% !important;
      margin-bottom: 0.25rem !important;
    }
    .form-col-input{
      width:100% !important;
    }
  }
  .quote-items-summary-wrapper {
    @include flex-element(0, 0, 0, 100%);
    background: $st-lighter-blue;
    font-weight: $font-weight-bold;
    padding: 0;
    margin-top:12px;
    .form-col-item{
      border-bottom:1px solid $white;
      margin: 0 !important;
      padding: calc($spacer/2) 0;
      .form-control{
        border: none !important;
        text-align:right;
        &:disabled,
        &[readonly]{
          background: $body-bg;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .quote-items-terms {
      @include flex-element(0, 0, 0, 55%);
    }

    .quote-items-summary {
      @include flex-element(0, 0, 0, 45%);
    }
    .quote-items-summary-wrapper {
      .form-col-item{
        padding-right:46px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .quote-items-terms {
      @include flex-element(0, 0, 0, 60%);
    }

    .quote-items-summary {
      @include flex-element(0, 0, 0, 40%);
    }
  }

  @include media-breakpoint-up(xl) {
    .quote-items-terms {
      @include flex-element(0, 0, 0, 70%);
    }
    .quote-items-summary {
      @include flex-element(0, 0, 0, 30%);
      .form-col-label{
        width:33.333% !important;
      }
      .form-col-input{
        width:66.667% !important;
      }
    }
  }
}

.tax-inline{
  position: relative;
  .btn-tax {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    padding:0.5rem;
    @include media-breakpoint-up(sm) {
      top:0.5rem;
      left:calc(1rem + 16.667%);
    }
    @include media-breakpoint-up(md) {
      left:calc(0.25rem + 25%);
    }
    @include media-breakpoint-up(lg) {
      left:25%;
    }
    @include media-breakpoint-up(xl) {
      left:32%;
    }
  }
}
.line-item-price-adjustment{
  margin: 0;
  .form-column{
    padding:0 !important;
    margin: -0.5rem 0;
  }
  .form-col-item{
    background: $st-lighter-blue;
    border-bottom:1px solid $white;
    margin: 0 !important;
    padding-top:0.25rem;
    padding-bottom:0.25rem;
  }
  .form-control{
    border:none !important;
    text-align:right;
    &[type="number"]{
      text-align:right;
    }
    &:disabled,
    &[readonly]{
      background: $st-lighter-blue;
    }
  }
}
.quote-item-thumbnail{
  width:48px !important;
  height:48px !important;
  object-fit: contain;
}
.toast-container{
  position: fixed;
  padding:1rem;
  top:0.5rem;
  right:2.5rem;
  @include media-breakpoint-up(xl) {
    top:2.5rem;
    right:4.5rem;
  }
}
.toast{
  border-radius:0.5rem;
}
.toast-header {
  color: $white;
  background-color: transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
  .btn-close,
  .btn-close:hover {
      color: $white;
      opacity:1;
  }
}
.toast-body {
  background-color: rgba(255, 255, 255, 0.95);
  color: $body-color;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.save-notification-toast{
  .toast-body {
    border-radius: 0.5rem !important;
  }
}

#StatusSettingsForm {
	dl {
		@include flex-container(row, wrap, flex-start, stretch, flex-start);

		dd, dt {
			@include flex-element(0, 0, 0, 100%);
			padding: 0.5rem 0.5rem;
			min-height: 2rem;

			&:nth-of-type(even) {
				background: $gray-100;
			}
		}

		dt {
			@include media-breakpoint-up(sm) {
				@include flex-element(0, 0, 0, 33.333333%);
			}

			@include media-breakpoint-up(xl) {
				@include flex-element(0, 0, 0, 25%);
			}
		}

		dd {
			@include media-breakpoint-up(sm) {
				@include flex-element(0, 0, 0, 66.666666%);
				margin-bottom: 0;
			}

			@include media-breakpoint-up(xl) {
				@include flex-element(0, 0, 0, 75%);
			}
		}
	}
}
#StatusSettingsForm {
	h5 {
		font-size: $h3-font-size;
	}
}
.settings-status-definition-labels {
	@include flex-container(row, wrap, flex-start, flex-start, flex-start);

	.states-list {
		@include flex-element(0, 0, 0, auto);
		max-width: 100%;
		margin-right: 1rem;
		margin-bottom: 0.5rem;

		> div {
			display: inline-block;
			margin: 0 0.125rem 0.5rem 0.125rem;
			color: $white;
			padding: 0.125rem 0.5rem;
			min-width: 60px;
			text-align: center;

			&.state-header {
				display: block;
				margin: 0 0 0.5rem 0;
				background: $gray-600
			}
		}
	}
}
.state-draft,
.badge-draft {
	background: $color-draft;
}
.state-active,
.badge-active {
	background: $color-active;
}
.state-issued,
.badge-issued {
	background: $color-issued;
}
.state-accepted,
.badge-accepted {
	background: $color-accepted;
}
.state-lapsed,
.badge-lapsed {
	background: $color-lapsed;
}
.state-rejected,
.badge-rejected {
	background: $color-rejected;
}
.state-cancelled,
.badge-cancelled {
	background: $color-rejected;
}
.state-completed,
.badge-completed {
	background: $color-completed;
}
.state-reconcilled,
.badge-reconcilled {
	background: $color-completed;
}
.quote-defaults-header{
  @include flex-container(row, wrap, space-between, center, center);
  margin-bottom:1rem;
  >*{
    @include flex-element(0, 0, 0, auto);
  }
  @include media-breakpoint-up(sm) {
    .quote-deafults-buttons{
      text-align:right;
    }
    h3{
      margin: 0 2rem 0 0;
    }
  }
}
.extended-options-line{
  margin: 0.5rem;
  font-size: $font-size-sm;
  .extended-options-block{
    margin-bottom:0.5rem;
    border-left:1px solid $gray-150;
    @include media-breakpoint-down(sm) {
      width:100%;
    }
    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .col-lg-3;
    @include media-breakpoint-up(xl) {
      width:20%;
    }
    @include media-breakpoint-up(xxl) {
      width:16.666666%;
    }
    h5{
      font-size: $font-size-base;
      margin: 0 0 0.25rem 0;
      font-weight: $font-weight-bold;
    }
    ul{
      margin: 0;
      padding:0;
      list-style: none;
      li{
        position: relative;
        margin:0;
        padding: 0 0 0.1rem 1rem;
        &:before{
          content:"";
          position: absolute;
          display:block;
          left:0;
          top:0.5rem;
          width:0.5rem;
          height:1px;
          background: $st-blue;
        }
        .attribute-label{
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  .extended-options-level1{
    padding-left:0;
    .extended-options-level-header{
      color: $st-blue;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      text-transform: uppercase;
      padding:0.5rem 1rem;
      background: $gray-150;
    }
    .extended-options-level2{
      padding-left:1rem;
    }
  }
}
.options-dropdown{
  color: $st-blue;
  cursor: pointer;
  z-index: 2;
  position: relative;
}
.defaults-verification-progress-wrapper{
  padding:0.5rem 1rem;
  background-color: $white !important;
  .text-primary{
    color: $st-blue !important;
  }
  .defaults-stripped-row{
    margin:0;
  }
}
.defaults-group-wrapper{
  margin:0;
  .text-primary{
    color: $st-blue !important;
  }
  .defaults-group-header{
    color: $white;
    font-weight: $font-weight-bold;
    font-size: $h4-font-size;
    padding:0.5rem 1rem;
    background: $st-mid-blue;
    margin:0;
  }
  .defaults-subgroup-header{
    color: $body-color;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    padding:0.5rem 1rem;
    background: $gray-150;
  }
  >ul{
    margin:0;
    padding:0;
    list-style: none;
  }
  .checkbox-icon{
    white-space: nowrap;
    .fa-check{
      color: $st-blue;
    }
  }
  .default-group-items-list{
    margin:0.5rem 0 0.5rem 0.5rem;
  }
  .default-group-items-header{
    margin:0 0 0.5rem 0;
  }
}
.defaults-stripped-row-header{
  font-weight:$font-weight-bold;
}
.defaults-stripped-row{
  padding:0.1rem 0;
  border-bottom:1px solid $gray-200;
  cursor: default;
  &:nth-child(odd){
    background: $white;
  }
  &:nth-child(even){
    background: $gray-150;
  }
  &:hover{
    background: $st-white-blue !important;
    border-bottom:1px solid $st-blue;
  }
}
.modal-backdrop,
.readonly-background,
#readonly-background{
  background: $shadow-35;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  &.show{
    opacity: 1;
  }
}
.spinner-grow.push-right{
  top: -1.6rem;
  right: 0.6rem;
  width:1rem;
  height:1rem;
  color: $st-blue !important;
}
.branding-wrapper{
  .branding-image-description{
    margin-bottom:1rem;
  }
  .image-upload-image{
    .form-col-input{
      width:100% !important;
      margin:0 !important;
    }
  }
  .image-upload-field{
    label{
      display: none !important;
    }
    .form-col-input{
      width:100% !important;
    }
  }
}
.quoteItemValidationWrapper{
  margin:0.5rem 0;
  >h2{
    font-size: $font-size-base;
  }
  .alert{
    padding:0.25rem;
    margin-bottom:0;
  }
}

#splash-screen{
  min-height:100vh;
  padding:3rem;
  @include flex-container(row, wrap, center, center, center);
  >*{
    @include flex-element(0, 0, 0, 100%);
  }
  .splash-screen-logo{
    margin-bottom:2rem;
    text-align: center;
    img{
      width:240px;
      height: auto;
      object-fit: contain;
    }
  }
  .splash-screen-text{
    text-align: center;
  }
}
.address-map{
  background: $gray-150 url(../assets/images/worldmap.svg) no-repeat center center;
  border:1px solid $gray-200;
  min-height:240px;
  background-size: 85% 85%;
}
.frm-client-details{
  padding-bottom:1rem;
}
div.dataTables_wrapper div.dataTables_info{
  padding-top:0;
}

.purchase-order-price-summary > .row{
  justify-content: space-between !important;
  .purchase-order-items-summary {
    @include flex-element(0, 0, 0, 100%);
    background: $st-lighter-blue;
    font-weight: $font-weight-bold;
    padding: 0;
    margin:12px 1rem 0 1rem;
    .form-col-item{
      border-bottom:1px solid $white;
      margin: 0 !important;
      padding: calc($spacer/2) 0;
      .form-control,
      input{
        border: none !important;
        text-align:right;
        &:disabled,
        &[readonly]{
          background: $body-bg;
        }
        &.form-control-plaintext{
          &:focus,
          &:focus-visible {
            border:none !important;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .purchase-order-items-summary {
      @include flex-element(0, 0, 0, 40%);
    }
  }

  @include media-breakpoint-up(lg) {
    .purchase-order-items-summary {
      @include flex-element(0, 0, 0, 30%);
    }
  }

  @include media-breakpoint-up(xl) {
    .purchase-order-items-summary {
      @include flex-element(0, 0, 0, 20%);
      .form-col-label{
        width:33.333% !important;
      }
      .form-col-input{
        width:66.667% !important;
      }
    }
  }
}
table.dataTable,
.table-responsive > table{
  >thead,
  >tbody{
    width:100% !important;
    tr{
      width:100% !important;
      td{
        //Quotes List
        &.item-menu{
          padding-top:0;
          padding-bottom:0;
        }
      }
      th,
      td{
        box-sizing: border-box;
        //Quotes List
        &.item-menu{
          @extend .no-pseudo;
          @include flex-element-fixed(0, 0, 0, 70px);
          text-align:center !important;
        }
        &.quote-modified-date{
          @include flex-element-fixed(0, 0, 0, 200px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
          @include media-breakpoint-down(lg) {
            display:none !important;
          }
        }
        &.quote-amount{
          @include flex-element-fixed(0, 0, 0, 140px);
          @extend .no-pseudo;
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 100px);
          }
          @include media-breakpoint-down(md) {
            @include flex-element-fixed(0, 0, 0, 140px);
          }
        }
        &.quote-type{
          @include flex-element-fixed(0, 0, 0, 100px);
          @include media-breakpoint-down(xl) {
            display:none !important;
          }
        }
        &.quote-status{
          @include flex-element-fixed(0, 0, 0, 100px);
          @include media-breakpoint-down(lg) {
            display:none !important;
          }
        }
        &.quote-project-number{
          @include flex-element-fixed(0, 0, 0, 300px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 240px);
          }
          @include media-breakpoint-down(mlg) {
            display:none !important;
          }
        }
        &.quote-number{
          @include flex-element-fixed(0, 0, 0, 110px);
        }
        &.quote-title{
          @include flex-element(0, 1, 1, 150px);
          @include  media-breakpoint-down(xl) {
            min-width: 150px;
          }
        }
        &.quote-client-name{
          @include flex-element-fixed(0, 0, 0, 100px);
          @include media-breakpoint-up(md) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
          @include media-breakpoint-up(lg) {
            @include flex-element-fixed(0, 0, 0, 150px);
          }
          @include media-breakpoint-up(xl) {
            @include flex-element-fixed(0, 0, 0, 260px);
          }
        }
        // Projects list
        &.project-number{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.project-title{
          @include flex-element(0, 1, 1, 200px);
        }
        &.project-client{
          @include flex-element-fixed(0, 0, 0, 280px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 220px);
          }
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 180px);
          }
        }
        &.project-client-name{
          @include flex-element-fixed(0, 0, 0, 240px);
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
        }
        &.project-modified-date{
          @include flex-element-fixed(0, 0, 0, 200px);
          @include media-breakpoint-down(xl) {
            display:none !important;
          }
        }
        // Quote Items list
        &.quote-item-no{
          @include flex-element-fixed(0, 0, 0, 60px);
        }
        &.quote-item-image{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.quote-item-title{
          @include flex-element(0, 1, 1, 200px);
        }
        &.quote-item-description{
          @include flex-element-fixed(0, 0, 0, 360px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 200px);
          }
        }
        &.quote-item-quantity{
          @include flex-element-fixed(0, 0, 0, 100px);
        }
        &.quote-item-modified{
          @include flex-element-fixed(0, 0, 0, 200px);
          @include media-breakpoint-down(xl) {
            display:none !important;
          }
        }
        &.quote-item-price{
          @include flex-element-fixed(0, 0, 0, 120px);
          @extend .no-pseudo;
        }
        //Project quotes
        &.project-quote-created-date{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.project-quote-number{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.project-quote-title{
          @include flex-element(0, 1, 1, 200px);
        }
        &.project-quote-type{
          @include flex-element-fixed(0, 0, 0, 160px);
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
        }
        &.project-quote-state{
          @include flex-element-fixed(0, 0, 0, 160px);
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
        }
        &.project-quote-price{
          @include flex-element-fixed(0, 0, 0, 120px);
          @extend .no-pseudo;
        }
        //Project POs
        &.project-order-number{
          @include flex-element-fixed(0, 0, 0, 260px);
        }
        &.project-order-quote-number{
          @include flex-element(0, 1, 1, 200px);
        }
        &.project-order-state{
          @include flex-element-fixed(0, 0, 0, 160px);
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
        }
        &.project-order-price{
          @include flex-element-fixed(0, 0, 0, 180px);
          @extend .no-pseudo;
        }
        //PO list
        &.purchase-order-number{
          @include flex-element-fixed(0, 0, 0, 140px);
        }
        &.purchase-order-quote-title{
          @include flex-element(0, 1, 1, 150px);
        }
        &.purchase-order-project-title{
          @include flex-element-fixed(0, 0, 0, 400px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 280px);
          }
        }
        &.purchase-order-state{
          @include flex-element-fixed(0, 0, 0, 100px);
        }
        &.purchase-order-total{
          @include flex-element-fixed(0, 0, 0, 140px);
          @extend .no-pseudo;
        }
        //PO Items list
        &.order-item-image{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.order-item-title{
          @include flex-element(0, 1, 1, 200px);
        }
        &.order-item-description{
          @include flex-element-fixed(0, 0, 0, 360px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 200px);
          }
        }
        &.order-item-quantity{
          @include flex-element-fixed(0, 0, 0, 100px);
        }
        &.order-item-price{
          @include flex-element-fixed(0, 0, 0, 120px);
          @extend .no-pseudo;
        }
        //Clients list
        &.client-name{
          @include flex-element-fixed(0, 0, 0, 280px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 180px);
          }
        }
        &.client-contact-name{
          @include flex-element-fixed(0, 0, 0, 280px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 180px);
          }
        }
        &.client-contact-email{
          @include flex-element-fixed(0, 0, 0, 280px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 180px);
          }
        }
        &.client-address{
          @include flex-element(0, 1, 1, 200px);
        }
        &.client-client-type{
          @include flex-element-fixed(0, 0, 0, 200px);
        }
        //Contacts List
        &.contact-name{
          @include flex-element(0, 1, 1, 200px);
        }
        &.contact-is-primary{
          @include flex-element-fixed(0, 0, 0, 80px);
        }
        &.contact-client-name{
          @include flex-element-fixed(0, 0, 0, 360px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 240px);
          }
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 160px);
          }
        }
        &.contact-email{
          @include flex-element-fixed(0, 0, 0, 240px);
          @include media-breakpoint-down(xl) {
            @include flex-element-fixed(0, 0, 0, 160px);
          }
        }
        &.contact-title{
          @include flex-element-fixed(0, 0, 0, 120px);
        }
        &.contact-mobile{
          @include flex-element-fixed(0, 0, 0, 200px);
          @include media-breakpoint-down(lg) {
            @include flex-element-fixed(0, 0, 0, 120px);
          }
        }
        // Client Types List
        &.payment-profile-name{
          @include flex-element(0, 1, 1, 200px);
        }
        &.payment-profile-margin{
          @include flex-element-fixed(0, 0, 0, 200px);
        }
      }
    }
  }
}
td.quote-item-image{
    text-align: center;
}
.modal-dialog:not(.modal-fullscreen){
  .data-table{
    max-height:65vh;
  }
}
